@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";
@import "../../assets/admin/css/default/app.min.css";
.breadcrumb .active {
    color: #0e0e0e !important;
}
.dropdown-item {
    padding: 5px 15px;

    text-decoration: none;
}
.news-login {
    background: url("https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/login-bg-12.jpg")
        no-repeat;
}
.news-forgot {
    background: url("https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/login-bg-10.jpg")
        no-repeat;
}

button.btn .btn-link {
    padding: 20px;
}

.intro-content {
    padding: 75px 20px;
}

.login.login-v2 .login-content .form-control {
    border: 1px solid #fff !important;
}

i.fas.fa-heart {
    color: red;
}
i.fa.fa-trash {
    color: red;
}
.d-md-inline {
    cursor: pointer;
}
.header .navbar-user .image,
.header .navbar-user img {
    cursor: pointer;
}
.form-control.pending {
    border-color: red;
    padding-right: calc(1.5em + 14px);
}
.form-control.approved {
    border-color: green;
    padding-right: calc(1.5em + 14px);
}

/*===========Question answer============*/

.forum-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.forum-list > li {
    padding: 0.9375rem;
}

.forum-list > li .media {
    font-size: 2rem;
    float: left;
    width: 6rem;
    text-align: center;
    line-height: 4rem;
}

.media {
    display: flex;
    align-items: flex-start;
}

.forum-list > li .media img {
    max-width: 100%;
    display: block;
}

.rounded-lg {
    border-radius: 6px !important;
    max-width: 40% !important;
    margin-left: 25px;
}

img {
    border-style: none;
    vertical-align: middle;
}

.forum-list.forum-topic-list .info-container {
    position: relative;
}
.forum-list > li .info-container {
    margin-left: 4.9375rem;
    font-size: 0.8125rem;
    line-height: 1.8;
    color: #6c757d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.forum-list > li .info-container .info {
    width: 50%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.forum-list > li .info-container .info .title {
    font-size: 1rem;
    font-weight: 600;
    line-height: inherit;
    margin: 0;
}

.forum-list > li .info-container .info .title a {
    color: #2d353c;
    text-decoration: none;
    background-color: transparent;
}

.forum-list.forum-topic-list .info-start-end {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 0.73125rem;
}

.forum-list.forum-topic-list .date-replies {
    text-align: center;
    width: 5rem;
}

.forum-list.forum-topic-list .date-replies .time {
    font-size: 0.73125rem;
    margin-bottom: 0.625rem;
    color: #6c757d;
}

.forum-list.forum-topic-list .date-replies .replies {
    background: #eff1f3;
    padding: 0.3125rem 0.625rem;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.forum-list.forum-topic-list .date-replies .replies .total {
    font-size: 1rem;
    font-weight: 600;
    color: #2d353c;
    margin: 0;
}

.forum-list.forum-topic-list .date-replies .replies .text {
    font-size: 0.73125rem;
    font-weight: 600;
    color: #6c757d;
}
/*.forum-list > li + li,
.threads-list > li + li {
  border-top: 2px solid #dee2e6;
}*/

/*=======question reply=====*/

.page-title.has-bg {
    overflow: hidden;
}

.page-title {
    padding: 5.625rem 0 1.875rem;
    z-index: 1020;
}

.page-title.has-bg .bg-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background: url("https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/cover-3.jpg")
        no-repeat;
    background-position: center 0px;
    height: 300px;
}

.page-title.has-bg .container {
    position: relative;
}

.page-title.has-bg .breadcrumb {
    color: #fff;
}

.page-title .breadcrumb {
    background: 0 0;
    padding: 0;
    font-weight: 400;
    margin-bottom: 0.625rem;
    font-size: 0.8125rem;
    flex-wrap: wrap;
}

.page-title.has-bg .breadcrumb li a {
    color: rgba(255, 255, 255, 0.75);
}

.page-title h1 {
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    margin: 0;
    line-height: 1.2;
}

.forum-list.forum-detail-list {
    border: 0;
    margin-bottom: 1.25rem;
    margin-top: 60px;
}

.forum-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.forum-list.forum-detail-list > li {
    padding: 0;
}

.forum-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.forum-list.forum-detail-list .media {
    display: block;
}

.forum-list > li .media {
    font-size: 2rem;
    float: left;
    width: 6rem;
    text-align: center;
    line-height: 4rem;
}

.media {
    display: flex;
    align-items: flex-start;
}

.forum-list.forum-detail-list .media img {
    margin-bottom: 0.625rem;
}

.forum-list > li .media img {
    max-width: 100%;
    display: block;
}

.rounded-lg {
    border-radius: 6px !important;
}

.forum-list.forum-detail-list .media .badge {
    font-size: 0.73125rem;
    display: block;
    padding: 0.25rem 0.375rem;
    font-weight: 500;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.badge-danger {
    color: #fff;
    background-color: #ff5b57;
}

.forum-list.forum-detail-list .info-container {
    border: 2px solid #dee2e6;
    padding: 0.9375rem 1.25rem;
    background: #f8f9fa;
    position: relative;
    display: block;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}
.forum-list.forum-detail-list .info-container .post-content {
    font-size: 0.875rem;
    line-height: 1.5;
    color: #2d353c;
    margin-bottom: 15px;
}

.forum-list > li .info-container {
    margin-left: 4.9375rem;
    font-size: 0.8125rem;
    line-height: 1.8;
    color: #6c757d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.forum-list.forum-detail-list .info-container .post-user {
    font-size: 0.875rem;
    margin-bottom: 0.9375rem;
}

.forum-list.forum-detail-list .info-container > div {
    float: none;
}

.forum-list.forum-detail-list .info-container .post-user small {
    font-weight: 700;
    color: #adb5bd;
    font-size: 0.7rem;
    margin-left: 0.3125rem;
}

.forum-list.forum-detail-list .info-container > div {
    float: none;
}

.forum-list.forum-detail-list .info-container .post-time {
    color: #adb5bd;
}

.forum-list.forum-detail-list .info-container:after,
.forum-list.forum-detail-list .info-container:before {
    content: "";
    position: absolute;
    left: -1.25rem;
    top: 1.25rem;
    border: 10px solid transparent;
    border-right-color: #dee2e6;
}

.listq {
    font-size: 0.8rem;
    font-weight: 400;
}

img.img.height-12 {
    width: 100% !important;
}

.admin-footer .footer {
    z-index: 1020;
    padding: 10px 0;
    margin: 0 30px 0 250px;
    border-top: 1px solid #c6ced5;
    line-height: 20px;
    font-weight: 600;
    color: #4e5c68;
    background: none;
}

.admin .btn-icon.btn-xs {
    width: 16px;
    height: 16px;
    font-size: 8px !important;
    line-height: 16px;
}

.admin .breadcrumb {
    display: block;
    padding: 0;
    margin: 0;
    background: 0 0;
}

.admin .breadcrumb .breadcrumb-item.active {
    color: #6c757d !important;
}
.admin .pull-right {
    float: right !important;
}

.admin .breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    font-size: 12px;
}

.page-header {
    color: #222;
}

.admin.btn,
.admin.btn.btn-sm,
.admin.btn.btn-xs {
    font-size: 12px;
}

.admin .close:not(:disabled):not(.disabled) {
    margin-right: 0px;
}

.admin .fa-fw {
    color: #fff !important;
}

button.btn.btn-lg.btn-warning {
    background: #d38109;
    border-color: #d38109;
    padding: 10px;
}
img.img.height-75 {
    width: 25%;
}
