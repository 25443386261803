@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";
@import "../../assets/admin/css/default/app.min.css";

/*-----responsive-----------*/
@media (max-width: 767.98px) {
    .member-sidebar {
        padding-top: 0;
        left: -250px;
        z-index: 1021;
    }
}
/*-----responsive-----------*/

.fa-trash {
    color: red;
}

body.member-body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 1.42857143;
}

.form-group label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 400 !important;
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 400 !important;
}

button.btn.btn-lg.btn-danger {
    padding: 10px;
}

li.nav-item {
    cursor: pointer;
}

.panel.panel-inverse.member-panel .panel-heading {
    background: #053042;
}

.member-panel .panel-heading .panel-title {
    font-size: 16px;
}

.member-content {
    margin-left: 20px;
}
.member-sidebar {
    width: 250px;
    background: #053042;
}

.member-sidebar .nav > li.active > a {
    position: relative;
    z-index: 10;
    color: #053042;
    background: #f4f7f6;
}

.member-sidebar .sidebar-minify-btn {
    margin: 10px 0;
    float: right;
    padding: 5px 20px 5px 10px !important;
    background: #053042;
    color: #fff;
    border-radius: 20px 0 0 20px;
}

.member-sidebar .sidebar-minify-btn:hover {
    background: #053042 !important;
}

.sweet-alert .btn-danger {
    color: #fff;
    background-color: #ff5b57 !important;
    border-color: #ff5b57;
}

.breadcrumb .active {
    color: #0e0e0e !important;
}
.dropdown-item {
    padding: 5px 15px;

    text-decoration: none;
}
.news-login {
    background: url("https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/login-bg-12.jpg")
        no-repeat;
}
.news-forgot {
    background: url("https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/login-bg-10.jpg")
        no-repeat;
}

button.btn .btn-link {
    padding: 20px;
}

.intro-content {
    padding: 75px 20px;
}

.login.login-v2 .login-content .form-control {
    border: 1px solid #fff !important;
}

i.fas.fa-heart {
    color: red;
}
i.fa.fa-trash {
    color: red;
}
.d-md-inline {
    cursor: pointer;
}
.header .navbar-user .image,
.header .navbar-user img {
    cursor: pointer;
}
.form-control.pending {
    border-color: red;
    padding-right: calc(1.5em + 14px);
}
.form-control.approved {
    border-color: green;
    padding-right: calc(1.5em + 14px);
}

/*===========Question answer============*/

.forum-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.forum-list > li {
    padding: 0.9375rem;
}

/*.forum-list > li .media {
  font-size: 2rem;
  float: left;
  width: 4rem;
  text-align: center;
  line-height: 4rem;
}

.media {
  display: flex;
  align-items: flex-start;
}

.forum-list > li .media img {
  max-width: 100%;
  display: block;
}

.rounded-lg {
  border-radius: 6px !important;
}

img {
  border-style: none;
  vertical-align: middle;
}*/

.forum-list.forum-topic-list .info-container {
    position: relative;
}
.forum-list > li .info-container {
    margin-left: 4.9375rem;
    font-size: 0.8125rem;
    line-height: 1.8;
    color: #6c757d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.forum-list > li .info-container .info {
    width: 50%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.forum-list > li .info-container .info .title {
    font-size: 1rem;
    font-weight: 600;
    line-height: inherit;
    margin: 0;
}

.forum-list > li .info-container .info .title a {
    color: #2d353c;
    text-decoration: none;
    background-color: transparent;
}

.forum-list.forum-topic-list .info-start-end {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 0.73125rem;
}

.forum-list.forum-topic-list .date-replies {
    text-align: center;
    width: 5rem;
}

.forum-list.forum-topic-list .date-replies .time {
    font-size: 0.73125rem;
    margin-bottom: 0.625rem;
    color: #6c757d;
}

.forum-list.forum-topic-list .date-replies .replies {
    background: #eff1f3;
    padding: 0.3125rem 0.625rem;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.forum-list.forum-topic-list .date-replies .replies .total {
    font-size: 1rem;
    font-weight: 600;
    color: #2d353c;
    margin: 0;
}

.forum-list.forum-topic-list .date-replies .replies .text {
    font-size: 0.73125rem;
    font-weight: 600;
    color: #6c757d;
}
/*.forum-list > li + li,
.threads-list > li + li {
  border-top: 2px solid #dee2e6;
}*/

/*=======question reply=====*/

.page-title.has-bg {
    overflow: hidden;
}

.page-title {
    padding: 5.625rem 0 1.875rem;
    z-index: 1020;
}

.page-title.has-bg .bg-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background: url("https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/cover-3.jpg")
        no-repeat;
    background-position: center 0px;
    height: 300px;
}

.page-title.has-bg .container {
    position: relative;
}

.page-title.has-bg .breadcrumb {
    color: #fff;
}

.page-title .breadcrumb {
    background: 0 0;
    padding: 0;
    font-weight: 400;
    margin-bottom: 0.625rem;
    font-size: 0.8125rem;
    flex-wrap: wrap;
}

.page-title.has-bg .breadcrumb li a {
    color: rgba(255, 255, 255, 0.75);
}

.page-title h1 {
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    margin: 0;
    line-height: 1.2;
}

.forum-list.forum-detail-list {
    border: 0;
    margin-bottom: 1.25rem;
    margin-top: 60px;
}

.forum-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.forum-list.forum-detail-list > li {
    padding: 0;
}

.forum-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.badge-danger {
    color: #fff;
    background-color: #ff5b57;
}

.forum-list.forum-detail-list .info-container {
    border: 2px solid #dee2e6;
    padding: 0.9375rem 1.25rem;
    background: #f8f9fa;
    position: relative;
    display: block;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    margin-bottom: 50px;
}

.forum-list.forum-detail-list .info-container .post-content {
    font-size: 0.875rem;
    line-height: 1.5;
    color: #2d353c;
    margin-bottom: 15px;
}

.forum-list > li .info-container {
    margin-left: 8rem;
    font-size: 0.8125rem;
    line-height: 1.8;
    color: #6c757d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.forum-list.forum-detail-list .info-container .post-user {
    font-size: 0.875rem;
    margin-bottom: 0.9375rem;
}

.forum-list.forum-detail-list .info-container > div {
    float: none;
}

.forum-list.forum-detail-list .info-container .post-user small {
    font-weight: 700;
    color: #adb5bd;
    font-size: 0.7rem;
    margin-left: 0.3125rem;
}

.forum-list.forum-detail-list .info-container > div {
    float: none;
}

.forum-list.forum-detail-list .info-container .post-time {
    color: #adb5bd;
}

.forum-list.forum-detail-list .info-container:after,
.forum-list.forum-detail-list .info-container:before {
    content: "";
    position: absolute;
    left: -1.25rem;
    top: 1.25rem;
    border: 10px solid transparent;
    border-right-color: #dee2e6;
}

.listq {
    font-size: 0.8rem;
    font-weight: 400;
}

img.img.height-12 {
    width: 100% !important;
}

.member-footer .footer {
    z-index: 1020;
    padding: 10px 0;
    margin: 0 30px 0 250px;
    border-top: 1px solid #c6ced5;
    line-height: 20px;
    font-weight: 400;
    color: #4e5c68;
    background: none;
}

.member .btn-icon.btn-xs {
    width: 16px;
    height: 16px;
    font-size: 8px !important;
    line-height: 16px;
}

.member .breadcrumb {
    display: block;
    padding: 0;
    margin: 0;
    background: 0 0;
}

.member .breadcrumb .breadcrumb-item.active {
    color: #6c757d !important;
}

.member .pull-right {
    float: right !important;
}

.member .breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    font-size: 15px;
}

.page-header {
    color: #222;
}

.member.btn,
.member.btn.btn-sm,
.member.btn.btn-xs {
    font-size: 12px;
}

.member .close:not(:disabled):not(.disabled) {
    margin-right: 0px;
}

.member .fa-fw {
    color: #fff !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #07a5b2;
}

option.optgroupp {
    font-weight: bold;
    color: #04bbca;
}

option.optgroup {
    font-weight: bold;
}

a.forward {
    background: #c8c8c8 !important;
    color: #fff;
    padding: 10px 20px 10px 20px !important;
    border-radius: 3px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
}

a.backward {
    background: #c8c8c8 !important;
    color: #fff;
    padding: 10px 20px 10px 20px !important;
    border-radius: 3px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
}

.for-bomb-icon img {
    height: auto;
    width: 35px;
    margin-top: 5px;
    cursor: pointer;
}

span.num-hov {
    cursor: pointer;
}

span.nav-study {
    cursor: pointer;
}

span.nav-study:hover {
    color: #fff;
}

.sidebar .sub-menu > li.active > span.nav-study {
    color: #fff;
}

.sidebar .nav .sub-menu > li > span.nav-study {
    color: rgba(255, 255, 255, 0.45);
}

.sidebar .sub-menu > li > span.nav-study {
    padding: 3px 20px 3px 15px;
    display: block;
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    position: relative;
}

.sidebar .sub-menu > li.has-sub > span.nav-study:before {
    background: #242b30;
}

.sidebar .nav .sub-menu > li > span.nav-study > .caret {
    margin-top: -1px;
    left: -5px;
    color: rgba(255, 255, 255, 0.25);
}

.sidebar li > span.nav-study > .caret {
    display: block;
    float: right;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    border: 0;
    font-family: "Font awesome 5 Free", "Font awesome 5 Pro", FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.sidebar .sub-menu > li > span.nav-study:after {
    content: "";
    position: absolute;
    left: -11px;
    top: 11px;
    width: 11px;
    height: 2px;
    background: #242b30;
}

.btn-outline,
button.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all 0.5s;
}

.btn-info.btn-outline,
.btn-info.btn-outline.fas {
    color: #5bc0de;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
button.btn-danger.btn-outline:hover {
    color: white;
}

button.btn.btn-outline.btn-danger.btn-sm.m-r-5,
button.btn.btn-outline.btn-danger.fas {
    color: #d9534f;
}

button.btn.btn-outline.btn-danger {
    border-color: #d43f3a;
}

.com-button button.btn.btn-outline.btn-danger:hover {
    color: white;
    background-color: #ac2925;
    border-color: #761c19;
}

span.hide-noti {
    display: none;
}

td.print-font {
    font-size: 14px;
}

table .pad-top tr td {
    padding-top: 20px;
    margin-top: 20px;
}

tr.table-green {
    background-color: SpringGreen;
}

tr.table-red {
    background-color: LightCoral;
}

.btn-indigo:hover {
    color: #fff;
    background-color: #6929d5 !important;
    border-color: #6929d5 !important;
}

.btn-purple:hover {
    color: #fff;
    background-color: #545fa1 !important;
    border-color: #545fa1 !important;
}

.btn-warning:hover {
    color: #fff;
    background-color: #d38109 !important;
    border-color: #d38109 !important;
}

.btn-lime:hover {
    color: #fff;
    background-color: #76af33 !important;
    border-color: #76af33 !important;
}

.btn-danger:hover {
    color: #fff;
    background-color: #ff2924 !important;
    border-color: #ff2924 !important;
}

.btn-default:hover {
    color: #212529;
    background-color: #d3dadf !important;
    border-color: #d3dadf !important;
}

button.btn.btn-lg.btn-success {
    padding: 10px;
}
img#print_image {
    width: 65%;
}

.col-md-12.padd.main-reply {
    margin-left: 10px;
}
