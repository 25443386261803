@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
    }
}
